<template>
  <div style="background-color: #F8F9FF;min-height: 100vh;">
    <div class="fixed_top">
    <back-nav-last-page class="title_top" :icon="'img/backb.png'" :textColor="'#111111'" :backColor="'#F8F9FF'" />
      <div class="cash_box display_flex justify-content_flex-justify align-items_center" style="background-image: url('img/game/cash_bg.png');">
        <div class="cash_total">
          <span style="color: #111111;">汇总</span>
          <p style="color: #111111;">{{ total_real_income }}</p>
        </div>
        <div class="cash_game display_flex align-items_center" @click="ListClick">
          <p>{{ gameList[gameCur].game_name }}</p>
          <img src="img/game/down.png" alt="">
        </div>
        <div class="cash_Glist" v-if="showList">
          <div class="cash_sort" style="background-image: url(img/game/sort.png);"></div>
          <div class="cash_Gitem" :class="{ 'game_cur': gameCur == index }" v-for="(item, index) in gameList" :key="index"
            @click="gameSelect(index)">{{ item.game_name }}</div>
        </div>
      </div>
    </div>
    <div class="cash_list">
      <van-pull-refresh v-model="refreshing" success-text="刷新成功" @refresh="onRefresh"
        class="padding-bottom-xl padding-lr-sm">
        <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="cashList" v-if="orderList.length>0">
          <div class="cash_item" v-for="(item, index) in orderList" :key="index">
            <div class="cash_time display_flex justify-content_flex-justify align-items_center">
              <p>{{ status(item.state) }}</p>
              <span style="color: #999">{{ item.created_at }}</span>
            </div>
            <div class="cash_detail display_flex align-items_center">
              <img :src="item.head_portrait" alt="">
              <div class="cash_text margin-left">
                <p style="color: #111111;">{{ item.nickname }}</p>
                <div class="display_flex align-items_center margin-top-sm" style="color: rgba(255, 255, 255, .4);">
                  <img :src="item.game_logo" alt="">
                  <span class="margin-lr-xs" style="color: #111111;">{{ item.game_name }}</span>
                  <span class="margin-right-xs">{{ item.price }}</span>
                  <img src="img/bi.png" alt="">
                  <span>/局</span>
                  <span class="margin-left-xs">x{{ item.num }}</span>
                </div>
              </div>
            </div>
            <div class="cash_money display_flex justify-content_flex-justify align-items_center">
              <div class="display_flex align-items_center">
                <span style="color: #111111;">已支付</span>
                <p class="margin-lr-xs">{{ item.total_price }}</p>
                <img src="img/bi.png" alt="">
              </div>
              <div class="display_flex align-items_center">
                <span style="color: #111111;">收益(元):</span>
                <div style="color: #111111;" class="cash_count margin-left-xs ">{{ item.real_income }}</div>
              </div>
            </div>
          </div>
        </van-list>
        <div class="noData margin-top-xl" v-if="orderList.length == 0">
          <img src="img/noData.png" alt="">
        </div>
      </van-pull-refresh>
    </div>
  </div>
</template>

<script>
import "@/assets/css/base.css"
import "@/assets/css/game.css"
import BackNavLastPage from '@/components/backNavLastPage.vue';
import { gameList, orderEarnings } from "@/api/api";
export default {
  name: 'gameCash',
  components: {
    BackNavLastPage
  },
  data() {
    return {
      isFixed: '',
      showList: false,
      gameCur: 0,
      gameList: [
        {
          game_name: '全部',
          type: 1
        }
      ],
      orderList: [],
      total_real_income: 0,
      page: 1,
      row: 10,
      loading: false,
      finished: false,
      refreshing: false,
    };
  },
  computed: {
    // 订单状态1待确认  2已取消  3已拒绝  4进行中 5已退款 6退款中 7已完成
    status() {
      return function (e) {
        if (e == 1) {
          return '待确认'
        } else if (e == 2) {
          return '已取消'
        } else if (e == 3) {
          return '已拒绝'
        } else if (e == 4) {
          return '已拒绝'
        } else if (e == 5) {
          return '进行中'
        } else if (e == 6) {
          return '进行中'
        } else if (e == 7) {
          return '进行中'
        } else if (e == 8) {
          return '进行中'
        } else if (e == 9) {
          return '已退款'
        } else if (e == 10) {
          return '已退款'
        } else if (e == 11) {
          return '退款中'
        } else if (e == 12) {
          return '已完成'
        } else if (e == 13) {
          return '已完成'
        }
      }
    }
  },
  created() {
    this.fetchData()
    this.cashList()
  },
  // mounted() {
  //   window.addEventListener('scroll', this.Scrollbottom);  //页面加载时监听滚动
  // },
  // destroyed() {
  //   window.removeEventListener('scroll', this.Scrollbottom) //页面离开后销毁监听事件
  // },
  methods: {
    fetchData() {
      gameList({ game_type: 3 }).then(response => {
        this.gameList = this.gameList.concat(response.data)
        console.log(this.gameList)
      },
        error => {
          this.$toast.fail(error)
        }).catch((error) => {
          this.$toast.fail(error)
        })
    },
    // Scrollbottom() {
    //   let scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
    //   let clientHeight = document.documentElement.clientHeight;
    //   let scrollHeight = document.documentElement.scrollHeight;
    //   if (scrollTop + clientHeight >= scrollHeight) {
    //     this.cashList()

    //   }
    // },
    cashList() {
      if (this.refreshing) {
        this.orderList = [];
        this.page = 1
        this.refreshing = false;
      }
      if (this.gameCur == 0) {
        var param = {
          page: this.page,
          rows: this.row
        }
      } else {
        var param = {
          page: this.page,
          rows: this.row,
          game_id: this.gameList[this.gameCur].id
        }
      }
      orderEarnings(param).then(response => {
        this.orderList = this.orderList.concat(response.data.order_list);
        this.total_real_income = response.data.total_real_income
        this.loading = false;
        if (response.data.order_list.length < 10) {
          this.finished = true;
        }
        this.page=this.page+1
      },
        error => {
          this.$toast.fail(error)
        }).catch((error) => {
          this.$toast.fail(error)
        })
    },
    onRefresh() {
      this.finished = false;
      this.loading = true;
      this.cashList();
    },
    ListClick() {
      let state = this.showList === true ? false : true;
      this.showList = state;
    },
    gameSelect(e) {
      this.gameCur = e
      this.showList = false
      this.orderList = [];
      this.page = 1
      this.loading = true;
      this.finished = false;
      this.cashList();
    },
    closePage() {
      let params = '调用'
      this.$bridge.callhandler('closePage', params, (data) => {
        // 处理返回数据
      })
    },
    // handleScroll() {
    //   let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop // 滚动条偏移量
    //   this.isFixed = scrollTop > 0 ? true : false;  // 如果滚动到顶部了，this.isFixed就为true
    // }

  },
};
</script>
<style>
.van-pull-refresh__track {
  position: static;
}

.van-pull-refresh__head {
  z-index: -1;
}
</style>
